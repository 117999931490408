<template>
  <div>
    <Products />
    <SideNav />
  </div>
</template>
<script>
// import MobileCategories from "../components/MobileCategories.vue";
import Products from "@/components/Products.vue";
import SideNav from "@/components/SideNav.vue";

export default {
  components: { Products, SideNav },
  data() {
    return {};
  },
  computed: {
    categories() {
      return this.$store.state.orderiomApiPackage.product.categories;
    },
    storageUrl() {
      return process.env.VUE_APP_STORAGE_URL;
    },
    currency() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo
        .currency;
    },
    restaurantId() {
      return localStorage.getItem("restaurantId");
    },
    user() {
      return this.$store.state.orderiomApiPackage.auth.user;
    },
  },
  mounted() {
    this.$store.dispatch(
      "product/getCategoriesWithProducts",
      this.restaurantId
    );
  },
};
</script>
