<template>
  <div>
    <div class="md:hidden">
      <transition
        enter-active-class="animate__animated animate__fadeInUp animate__fast"
        leave-active-class="animate__animated animate__fadeOutDown animate__fast"
      >
        <div
          class="list-in bg-white z-hard overflow-x-hidden rounded-t-3xl md:rounded-3xl shadow-2xl"
          v-if="showListPreview"
        >
          <div class="flex flex-col aic p-7 md:p-10">
            <div class="flex aic jcb w-full border-b mb-2 pb-2">
              <h2 class="text-gray-700 subway_footlong text-base md:text-xl min-w-min">Ihre ausgewählten Artikel</h2>
              <button
                @click="$emit('closeList')"
                type="button"
                class="flex aic jce text-primary w-35p h-40p"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div class="flex flex-col ais jcc text-gray-700 w-full">
              <div
                class="flex aic jcb w-full border-b-2 border-dashed py-2 md:py-4 border-gray-100"
                v-for="(p,i) in productList"
                :key="i"
              >
                <img
                  :src="
                      p.product.image ? storageUrl + p.product.image : '/img/dish.webp'
                    "
                  alt="#"
                  class="w-50p"
                >
                <h2 class="mr-auto ml-4 text-xs md:text-base">{{p.product.name}}</h2>
                <span class="flex aic text-sm md:text-lg b text-gray-900">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-10p mr-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                  {{p.quantity}}
                </span>
              </div>

            </div>
          </div>
        </div>
      </transition>
    </div>

    <div class="hidden md:block">
      <transition
        enter-active-class="animate__animated animate__fadeIn animate__fast"
        leave-active-class="animate__animated animate__fadeOut animate__fast"
      >
        <div
          class="list-in bg-white z-hard overflow-x-hidden rounded-t-3xl md:rounded-3xl shadow-2xl"
          v-if="showListPreview"
        >
          <div class="flex flex-col aic p-7 md:p-10">
            <div class="flex aic jcb w-full border-b mb-2 pb-2">
              <h2 class="text-gray-700 subway_footlong text-base md:text-xl min-w-min">Ihre ausgewählten Artikel</h2>
              <button
                @click="$emit('closeList')"
                class="flex aic jce text-primary w-35p h-40p"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div
              class="flex flex-col ais jcc text-gray-700 w-full"
              v-for="(p,i) in productList"
              :key="i"
            >
              <div class="flex aic jcb w-full border-b-2 border-dashed py-2 md:py-4 border-gray-100">
                <img
                  :src="
                      p.product.image ? storageUrl + p.product.image : '/img/dish.webp'
                    "
                  alt="#"
                  class="w-50p"
                >
                <h2 class="mr-auto ml-4 text-xs md:text-base">{{p.product.name}}</h2>
                <span class="flex aic text-sm md:text-lg b text-gray-900">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-10p mr-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                  {{p.quantity}}
                </span>
              </div>

            </div>
          </div>
        </div>
      </transition>
    </div>
    <transition
      enter-active-class="animate__animated animate__fadeIn animate__fast"
      leave-active-class="animate__animated animate__fadeOut animate__fast"
    >
      <div
        class="cart-overlay"
        v-if="showListPreview"
        @click="$emit('closeList')"
      ></div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "showListPreview",
  props: ["showListPreview", "productList", "storageUrl"],
};
</script>




