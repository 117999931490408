<template>
  <div>
    <form
      class="flex flex-col w-full px-5 xl:px-20 prod-area pb-20 md:pb-32"
      @submit.prevent="createPattern"
    >
      <div class="flex top-2-area w-full mx-auto aic jcb">
        <div class="flex w-full py-5 border-b relative mt-4 lg:mt-16">
          <div class="flex aic w-full">
            <div class="flex flex-col ais jcb">
              <h2 class="w-full capitalize nunitoSans-bold">Bitte geben Sie Ihre Daten ein</h2>
            </div>
            <div class="absolute bottom-0 right-0 w-90p h-5p bg-primary"></div>
          </div>
        </div>
      </div>
      <div class="flex flex-col ais jcb w-full md:mb-10 xl:mb-20">
        <div class="flex flex-col sm:flex-row space-y-6 sm:space-y-0 sm:space-x-8 py-1 mt-10 mb-10 lg:mb-16 aic w-full lg:max-w-xl">
          <div class="w-full">
            <label
              for="name"
              class="block text-gray-500 text-sm mb-3"
            >{{ $t("Name") }}</label>
            <input
              type="text"
              v-model="name"
              id="name"
              required
              class="focus:ring-indigo-500 focus:border-indigo-500 w-full pr-10 text-sm border-gray-300 rounded-md p-3 h-50p shadow-lg text-secondary"
            >
          </div>
          <div class="w-full calendar">
            <label
              for="date"
              class="block text-gray-500 text-sm mb-3"
            >{{ $t("Wählen Sie das Startdatum") }}</label>
            <div class="mt-1 relative rounded-md pointer">
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer pointer-events-none text-xl text-gray-600 h-50p rounded-xl px-5 fcc">
                <i class="fa fa-calendar"></i>
              </div>

              <datetime
                v-model="start_time"
                input-class="focus:ring-indigo-500 focus:border-indigo-500 w-full pr-10 text-sm border-gray-300 rounded-md p-3 h-50p shadow-lg"
                :placeholder="$t('Select Date')"
                format="dd.MM.yy"
                aria-label="Date Picker"
                :min-datetime="minDatetime()"
                required
              >
              </datetime>
            </div>
          </div>
        </div>
        <div class="hidden lg:flex aic jcs days-list w-full space-x-3">
          <div
            class="days-day flex flex-col aic rounded-lg"
            :class="{'selected': selectedDay== day.id}"
            v-for="day in weekdaysList"
            :key="day.id"
          >
            <button
              class="border border-gray-100 shadow-lg rounded-lg h-70p w-full flex ais jcs p-3 text-gray-600 days-overview capitalize text-xs"
              :class="{active : selectedDay== day.id}"
              @click="selectedDay = day.id"
              type="button"
            >
              {{$t(day.completeName)}}
              <div class="flex w-full aie jce relative h-full">
                <span class="text-primary absolute top-3 nunitoSans-bold pr-2">{{weekDayProductQty(day.id)}}</span>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7 mirror" viewBox="0 0 24 24">
                  <path d="M10 19.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5zm3.5-1.5c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm6.305-15l-3.432 12h-10.428l-3.777-9h-2.168l4.615 11h13.239l3.474-12h1.929l.743-2h-4.195z"/>
                </svg>
              </div>
            </button>
            <!-- <button
              class="absolute rounded-full shadow-lg list-preview"
              type="button"
              @click="()=>{getProductList(day.id);showListPreview = true}"
            >
              <i class="fa fa-shopping-basket"></i>
            </button> -->
          </div>

        </div>
      </div>
      <!-- <mobile-categories></mobile-categories> -->
      <span class="lg:hidden text-gray-500 text-sm">Bitte wählen Sie den Tag aus</span>

      <collapse
        :selected="false"
        class="lg:hidden mob-days"
      >
        <div
          class="py-5 fs-16 flex jcb aic border-b-4 b"
          slot="collapse-header"
        >
          {{weekdaysList.find(f=>f.id === selectedDay)?$t(weekdaysList.find(f=>f.id === selectedDay).completeName):''}}
          <div class="collapse-header-after"></div>
        </div>
        <div slot="collapse-body">
          <div class="flex aic jcb days-list w-full pt-8">
            <div
              class="days-day flex flex-col aic rounded-2xl"
              :class="{'selected': selectedDay== day.id}"
              v-for="day in weekdaysList"
              :key="day.id"
            >
              <button
                class="border border-gray-100 shadow-lg rounded-2xl h-70p w-full flex ais jcs p-3 text-gray-600 days-overview capitalize text-sm"
                :class="{active : selectedDay== day.id}"
                @click="selectedDay = day.id"
                type="button"
              >
                {{$t(day.name)}}
                <div class="flex w-full aic jce relative h-full">
                  <span class="text-primary absolute top-1 nunitoSans-bold pr-2">{{weekDayProductQty(day.id)}}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-7 h-7 mirror"
                    viewBox="0 0 24 24"
                  >
                    <path d="M10 19.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5zm3.5-1.5c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm6.305-15l-3.432 12h-10.428l-3.777-9h-2.168l4.615 11h13.239l3.474-12h1.929l.743-2h-4.195z" />
                  </svg>
                </div>
              </button>
              <!-- <button
                class="absolute rounded-full shadow-lg list-preview"
                type="button"
                @click="()=>{getProductList(day.id);showListPreview = true}"
              >
                <i class="fa fa-shopping-basket"></i>
              </button> -->
            </div>
          </div>
        </div>
      </collapse>

      <div class="w-full flex flex-col mt-6 md:mt-0">
        <!-- <span class="b text-white text-center bg-primary fs-18 w-full py-4 md:hidden">Dienstag</span> -->
        <span class="nunitoSans-bold mb-4 w-full py-4 hidden lg:flex">Dienstag</span>

        <collapse
          :selected="false"
          class="cat-collapse"
          v-for="(category,key) in categories"
          :key="key"
        >
          <div
            class="text-xs md:text-sm capitalize flex jcb aic cc-in"
            slot="collapse-header"
          >
            {{category.name}}
            <div class="flex w-50p aic jcc relative h-40p ml-auto mr-12 xl:mr-20">
              <div class="basket-img-layer">
                <img
                  :src="category.image ? storageUrl + category.image : '/img/dish.webp'"
                  :alt="category.name"
                  class="basket-img relative"
                >
              </div>
            </div>

            <div class="collapse-header-after"></div>
          </div>

          <div slot="collapse-body">
            <div class="w-full flex flex-wrap ais jcb mx-auto border-t px-2">
              <div
                class="flex flex-col lg:flex-row ais jcb list-items py-3 lg:py-9 border-b"
                v-for="product in category.products"
                :key="product.id"
              >
                <div class="flex h-full w-full lg:w-auto aic justify-between lg:justify-start lg:space-x-5">
                  <img
                    :src="
                      product.image ? storageUrl + product.image : '/img/dish.webp'
                    "
                    :alt="product.name"
                    class="li-img order-2 lg:order-1"
                  >
                  <div class="flex flex-col order-1 lg:order-2">
                    <div class="flex aic space-x-2">
                      <h2 class="text-sm text-gray-800 pi-title capitalize">{{product.name}}</h2>
                      <button
                        type="button"
                        @click="()=>{showInfoModal = true;
                      productDescription=product.description;
                      productImage=product.image;
                      productName=product.name}"
                        class="flex  aic jcc w-30p h-30p rounded-full text-primary fs-20"
                      ><i class="fa fa-info"></i></button>
                      <span
                        class="fcc text-green-500 text-xl lg:text-2xl"
                        v-if="productQtyinPattern(product.id)>0"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="3"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </span>
                    </div>
                    <!-- <span class="text-gray-500 text-sm lg:text-base">500 g</span> -->
                  </div>
                </div>
                <div class="flex h-full w-full lg:w-auto aic jcb space-x-6">
                  <div class="flex aic jcb space-x-1">
                    <button
                      class="bg-gray-200 bg-opacity-40 p-2 rounded-lg"
                      type="button"
                      @click="remove(product.id)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-3 w-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M18 12H6"
                        />
                      </svg>
                    </button>
                    <input
                      type="text"
                      name="qty"
                      class="w-30p text-center bg-transparent text-sm"
                      :value="productQtyinPattern(product.id)"
                    />
                    <button
                      class="bg-gray-200 bg-opacity-40 p-2 rounded-lg"
                      type="button"
                      @click="add(product)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-3 w-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        />
                      </svg>
                    </button>
                  </div>
                  <span class="text-sm nunitoSans-bold whitespace-nowrap pr-2 mt-2">{{product.price}} {{currency}}</span>
                </div>
              </div>
            </div>
          </div>
        </collapse>

      </div>
      <div class="flex aic jce w-full mt-16 lg:mt-32">
        <button
          type="submit"
          class="btn-primary w-full md:max-w-max px-16 h-60p rounded-lg shadow-lg fcc text-white"
        >
          Weitre
        </button>
      </div>
    </form>
    <ProductInfo
      :image="productImage"
      :showInfoModal="showInfoModal"
      :description="productDescription"
      :name="productName"
      @close="showInfoModal = false"
    ></ProductInfo>
    <ProductlistPreview
      @closeList="showListPreview = false"
      :showListPreview="showListPreview"
      :productList="productList"
      :storageUrl="storageUrl"
    ></ProductlistPreview>
  </div>
</template>
<script>
// import MobileCategories from "../components/MobileCategories.vue";
import Collapse from "./Collapse.vue";
import ProductInfo from "../components/ProductInfo.vue";
import ProductlistPreview from "../components/ProductlistPreview.vue";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
export default {
  components: { Collapse, Datetime, ProductInfo, ProductlistPreview },
  data() {
    return {
      showListPreview: false,
      showInfoModal: false,
      productImage: null,
      productName: null,
      productDescription: null,
      start_time: new Date().toISOString(),
      name: null,
      pattern: [
        { weekday: 1, products: [] },
        { weekday: 2, products: [] },
        { weekday: 3, products: [] },
        { weekday: 4, products: [] },
        { weekday: 5, products: [] },
        { weekday: 6, products: [] },
        { weekday: 7, products: [] },
      ],
      selectedDay: null,
      productList: null,
    };
  },
  computed: {
    storageUrl() {
      return process.env.VUE_APP_STORAGE_URL;
    },
    currency() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo
        .currency;
    },
    restaurantId() {
      return localStorage.getItem("restaurantId");
    },
    categories() {
      return this.$store.state.orderiomApiPackage.product.categories;
    },
    user() {
      return this.$store.state.orderiomApiPackage.auth.user;
    },
    UTCdate() {
      return this.start_time.split("T")[0];
    },
  },
  watch: {
    weekdaysList: {
      handler: function (val) {
        if (val && val.length) {
          this.selectedDay = val[0].id;
        }
      },
      immediate: true,
    },
  },
  methods: {
    getProductList(weekdayId) {
      this.productList = this.pattern.find(
        (p) => p.weekday == weekdayId
      ).products;
    },
    minDatetime() {
      return new Date().toISOString();
    },
    weekDayProductQty(dayId) {
      return this.pattern
        .find((item) => item.weekday === dayId)
        .products.reduce((a, b) => a + b.quantity, 0);
    },
    add(product) {
      var selectedDay = this.pattern.find(
        (item) => item.weekday === this.selectedDay
      );
      if (selectedDay.products.find((p) => p.product.id === product.id)) {
        this.pattern
          .find((item) => item.weekday === this.selectedDay)
          .products.find((element) => element.product.id === product.id)
          .quantity++;
      } else
        this.pattern
          .find((item) => item.weekday === this.selectedDay)
          .products.push({ product: product, quantity: 1 });
    },
    productQtyinPattern(productId) {
      if (this.selectedDay) {
        var selectedDay = this.pattern.find(
          (item) => item.weekday === this.selectedDay
        );
        if (selectedDay.products.find((p) => p.product.id === productId)) {
          return selectedDay.products.find((p) => p.product.id === productId)
            .quantity;
        }
      }
      return 0;
    },
    remove(productId) {
      var selectedDay = this.pattern.find(
        (item) => item.weekday === this.selectedDay
      );
      if (selectedDay.products.find((p) => p.product.id === productId)) {
        if (
          selectedDay.products.find((p) => p.product.id === productId)
            .quantity > 1
        ) {
          this.pattern
            .find((item) => item == selectedDay)
            .products.find((element) => element.product.id === productId)
            .quantity--;
        } else {
          var index = selectedDay.products.findIndex(
            (element) => element.product.id === productId
          );
          this.pattern
            .find((item) => item == selectedDay)
            .products.splice(index, 1);
        }
      } else return;
    },
    createPattern() {
      this.$store
        .dispatch("subscription/createPattern", {
          start_time: this.UTCdate,
          name: this.name,
          pattern: this.pattern
            .filter((item) => item.products.length)
            .map((m) => {
              return {
                weekday: m.weekday,
                products: m.products.map((p) => {
                  return {
                    id: p.product.id,
                    quantity: p.quantity,
                  };
                }),
              };
            }),
          delivery_type: process.env.VUE_APP_DELIVERY,
        })
        .then((res) => {
          if (res.type == "success") {
            this.$router.push("/dashboard/ansehen-anderen");
          } else
            this.$store.commit("setshowNotif", {
              show: true,
              msg: res.msg,
              type: res.type,
            });
        });
    },
  },
  mounted() {
    document.querySelectorAll('[name="fade"]');
  },
};
</script>
